import type { UrlForwardingDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlForwardingService {
  apiName = 'Default';
  

  createOrUpdate = (urlPairs: UrlForwardingDto[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, UrlForwardingDto[]>({
      method: 'PUT',
      url: '/api/app/url-forwarding',
      body: urlPairs,
    },
    { apiName: this.apiName,...config });
  

  deleteUrl = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/app/url-forwarding',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getByOldUrl = (oldUrl: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UrlForwardingDto>({
      method: 'GET',
      url: '/api/app/url-forwarding/by-old-url',
      params: { oldUrl },
    },
    { apiName: this.apiName,...config });
  

  getUrls = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, UrlForwardingDto[]>({
      method: 'GET',
      url: '/api/app/url-forwarding',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
